import './App.css';

function App() {
  return (
    <div className="App">
      <div className="primary m-5">
        <div className="row p-3">
          <div className="col-8">
            <h1 className="monospace">Amy Surrett<br/>Web Developer</h1>
          </div>
          <div className="col-4">
            <img className="w-50 rounded m-2" src="./amyprofilepic.png" alt="Amy profile"></img>
          </div>
        </div>
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container-fluid">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
          <li className="nav-item">
            <a className="nav-link" href="#skills">Skills</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#projects">Projects</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#certifications">Certifications</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#about-me">About Me</a>
          </li>
          </ul>
        </div>
        </nav>
        <h2 id="skills">Skills</h2>
        <h2 id="projects">Projects</h2>
        <h2 id="certifications">Certifications</h2>
        <h2 id="about-me">About Me</h2>
      </div>
    </div>
  );
}

export default App;
